import React from 'react';

import MainModal from '../UI/Modal/MainModal';
import AdditionalEmailForm from './AdditionalEmailForm';

interface IAdditionalEmailModalProps {
    open: boolean,
    handleClose: () => void;
}

const AdditionalChatModal: React.FC<IAdditionalEmailModalProps> = ({
    open,
    handleClose,
}) => {

    return (
        <MainModal
            header="Добавить E-mail"
            open={open}
            handleClose={handleClose}
            size="md"
        >
            <AdditionalEmailForm onSuccess={handleClose}/>
        </MainModal>
    );
};

export default AdditionalChatModal;