import React, {useEffect, useState} from 'react';
import {useMutex} from 'react-context-mutex';

import {useParams} from 'react-router';

import cn from 'classnames';

import {useDispatch} from 'react-redux';

import {unwrapResult} from '@reduxjs/toolkit';

import Box from '../UI/Box/Box';

import IUserOutput from '../../models/IUser';
import {AppUrls} from '../../constants/urls';

import LinkButton from '../UI/Button/LinkButton';
import {confirmChangeEmail} from '../../redux/authSlice';
import {TypedDispatch} from '../../types';

import {useIsMount} from '../../hooks/useIsMount';

import styles from './Auth.module.scss';


interface IProfileProps {
    user: IUserOutput,
}

const ConfirmChangeEmail: React.FC<IProfileProps> =  ({user}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const MutexRunner = useMutex();
    const mutex = new MutexRunner('changeEmailConfirm');
    const isMount = useIsMount();

    const {userId, token} = useParams();

    const [message, setMessage] = useState<string>();
    const [status, setStatus] = useState<boolean | null>(null);

    useEffect(() => {
        if(!token || !userId ||!user || !isMount) {
            return;
        }

        if(Number(userId) !== user.id) {
            setMessage('Некорректная ссылка');
            setStatus(false);
            return;
        }

        mutex.run(async() => {
            mutex.lock();
            try {
                const resultAction = await dispatch(confirmChangeEmail({userId: Number(userId), token}));
                const response = unwrapResult(resultAction);
                setMessage(response.message);
                setStatus(response.status);
            } catch (error: any) {
                setMessage(error.message);
                setStatus(false);
            }finally {
                mutex.unlock();
            }
        });

    }, [userId, token, user]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.box_wrapper_confirm}>
                <Box>
                    <div className="row text-center mb-3">
                        <div className="col-12">
                            <h2 className={styles.confirm_title}>Подтверждение изменения E-mail</h2>
                            {
                                message && (
                                    <p className={cn('py-2 m-0', [status
                                        ? styles.confirm_email__msg
                                        : styles.confirm_email__msg_error])}
                                    >
                                        {message}
                                    </p>
                                )
                            }
                        </div>
                    </div>

                    {
                        status &&
                        <LinkButton
                            className="full-width rs-btn-primary"
                            href={AppUrls.SETTINGS}
                        >Продолжить</LinkButton>
                    }
                </Box>
            </div>
        </div>
    );
};

export default ConfirmChangeEmail;