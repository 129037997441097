import React, {useState} from 'react';

import PlusCircleIcon from '@rsuite/icons/lib/icons/legacy/PlusCircle';

import TransparentButton from '../UI/Button/TransparentButton';
import AdditionalEmailModal from './AdditionalEmailModal';

const CreateAdditionalEmailButton: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClose = () => setIsModalOpen(false);

    return (
        <>
            <TransparentButton
                onClick={() => setIsModalOpen(true)}
            >
                Добавить E-mail<PlusCircleIcon width="20px" height="20px" />
            </TransparentButton>
            <AdditionalEmailModal open={isModalOpen} handleClose={handleClose}/>
        </>
    );
};

export default CreateAdditionalEmailButton;