import {MonitorStatus} from '../models/Monitor/IMonitor';
import {PaymentStatus} from '../models/PaymentStatus';
import {WarningType} from '../models/IWarning';

export const translateMonitorStatus = (status: MonitorStatus): string => {
    const map = {
        active: 'Активен',
        paused: 'На паузе',
        unsubscribed: 'Отписка',
    };

    return map[status];
};


export const translateIncidentStatus = (resolved: boolean): string => {
    return resolved ? 'Решен':'Не решен';
};

export const translatePaymentStatus = (status: PaymentStatus): string => {
    const map = {
        [PaymentStatus.Created]: 'Не оплачен',
        [PaymentStatus.Paid]: 'Оплачен',
        [PaymentStatus.Canceled]: 'Отменен',
    };

    return map[status];
};

export const translateWarningCause = (type: WarningType): string => {
    const map = {
        'ssl-expired': 'Истекает срок SSL сертификата',
        'ssl-redirect-missing': 'Не настроен редирект с http на https',
        'domain-expired': 'Истекает срок регистрации домена',
        'robots-txt-missing': 'Отсутствует файл robots.txt',
        'robots-txt-restricted': 'Файл robots.txt не доступен для чтения',
        'robots-txt-home-disallowed': 'Главная страницы сайта закрыта от индексирования',
        'robots-txt-site-map-not-specified': 'В файле robots.txt отсутствует ссылка на карту сайта',
    };

    return map[type];
};

export const getMonthName = (date: Date): string =>{

    const monthNames = [
        'январь',
        'февраль',
        'март',
        'апрель',
        'май',
        'июнь',
        'июль',
        'август',
        'сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь',
    ];

    return monthNames[date.getMonth()];
};