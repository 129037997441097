import React from 'react';

import {useSelector} from 'react-redux';

import {IMonitorItem} from '../../../models/Monitor/IMonitor';

import MonitorStatusBadge from '../../Status/MonitorStatusBadge';

import MonitorAvgResponseTimeChart from './MonitorAvgResponseTimeChart';

import MonitorLastDayIncidentsRanges from './MonitorLastDayIncidentsRanges';
import useBreakpoint from '../../../hooks/useBreakpoint';

import Box from '../../UI/Box/Box';

import {getFormattedDate} from '../../../utils/datetime';
import {userSelector} from '../../../redux/authSlice';

import OpenLinkNewWindow from '../../UI/IconButton/OpenLinkNewWindow';

import {buildDelaySelectOptions} from '../../UI/DelaySelector/DelaySelector';

import {
    MonitorContentCheckLogicOptionsTitle,
    MonitorContentCheckNotifyLogicOptionsTitle,
} from '../MonitorForm/MonitorContentCheckOptions';

import styles from './MonitorDetails.module.scss';

interface IMonitorDetailsProps {
    item: IMonitorItem,
}

const MonitorDetails: React.FC<IMonitorDetailsProps> = ({item}) => {
    const user = useSelector(userSelector);
    const tzString = user?.timezone;
    const currentBreakpoint = useBreakpoint();

    console.log(item);

    return (
        <Box className={styles.container}>
            <h3 className={styles.title}>{item.name}</h3>
            <div className={styles.wrapper}>
                <table className={styles.table}>
                    <tbody>
                        <tr>
                            <td>Статус:</td>
                            <td>
                                <MonitorStatusBadge status={item.status}/>
                            </td>
                        </tr>
                        <tr>
                            <td>URL:</td>
                            <td>
                                <OpenLinkNewWindow url={item.url_human_readable}/>
                            </td>
                        </tr>
                        <tr>
                            <td>Доступность за 24 часа:</td>
                            <td>{item.availability1}%</td>
                        </tr>
                        <tr>
                            <td>Доступность за 7 дней:</td>
                            <td>{item.availability7}%</td>
                        </tr>
                        <tr>
                            <td>Доступность за 30 дней:</td>
                            <td>{item.availability30}%</td>
                        </tr>
                        <tr>
                            <td>Монитор создан:</td>
                            <td>{getFormattedDate(
                                item.created_at,
                                {format: 'DD.MM.YYYY, в HH:mm', tzString},
                            )}</td>
                        </tr>
                        <tr>
                            <td>Последняя проверка:</td>
                            <td>{getFormattedDate(
                                item.last_check,
                                {format: 'DD.MM.YYYY, в HH:mm', tzString},
                            )}</td>
                        </tr>
                        <tr>
                            <td>Следующая проверка:</td>
                            <td>{getFormattedDate(
                                item.next_check,
                                {format: 'DD.MM.YYYY, в HH:mm', tzString},
                            )}</td>
                        </tr>
                        <tr>
                            <td>Метод запроса:</td>
                            <td>{ item.method }</td>
                        </tr>
                        <tr>
                            <td>Интервал проверки:</td>
                            <td>{ item.check_interval } мин.</td>
                        </tr>
                        <tr>
                            <td>Уведомления на подтвержденный Email:</td>
                            <td>
                                { item.notify_by_email &&
                                    <>
                                        Уведомление на {user!.email}
                                        {
                                           item.notify_email_confirmed_delay > 0
                                           && buildDelaySelectOptions().map((row, index) => {
                                               if(row.value === item.notify_email_confirmed_delay) {
                                                   return <> с задержкой {row.label}</>;
                                               }
                                           })
                                        }
                                    </>
                                }
                                { !item.notify_by_email && 'Отключено' }
                            </td>
                        </tr>
                        <tr>
                            <td>Уведомления на дополнительные Email:</td>
                            <td>
                                { item.additional_emails.length > 0 &&
                                    item.additional_emails.map((additionalEmailItem) => {
                                        return <p key={additionalEmailItem.id}>
                                            Уведомление на {additionalEmailItem.email}
                                            { item.notify_additional_emails_settings.length > 0 &&
                                                item.notify_additional_emails_settings.map((additionalEmailNotifyItem) => {
                                                    if(parseInt(String(additionalEmailNotifyItem.id)) === additionalEmailItem.id) {
                                                        return buildDelaySelectOptions().map((row, index) => {
                                                            if(row.value === additionalEmailNotifyItem.delay && additionalEmailNotifyItem.delay !== 0) {
                                                                return <> с задержкой {row.label}</>;
                                                            }
                                                        });
                                                    }
                                                })
                                            }
                                        </p>;
                                    })
                                }
                                { item.additional_emails.length <= 0 && 'Отключено' }
                            </td>
                        </tr>
                        <tr>
                            <td>Уведомления телеграмм:</td>
                            <td>
                                { item.telegram_chats.length > 0 &&
                                    item.telegram_chats.map((tgItem) => {
                                        return <p key={tgItem.id}>
                                            Уведомление на {tgItem.description}
                                            { item.notify_telegram_chats_settings.length > 0 &&
                                                item.notify_telegram_chats_settings.map((tgNotifyItem) => {
                                                    if(parseInt(String(tgNotifyItem.id)) === tgItem.id) {
                                                        return buildDelaySelectOptions().map((row, index) => {
                                                            if(row.value === tgNotifyItem.delay && tgNotifyItem.delay !== 0) {
                                                                return <> с задержкой {row.label}</>;
                                                            }
                                                        });
                                                    }
                                                })
                                            }
                                        </p>;
                                    })
                                }
                                { item.telegram_chats.length <= 0 && 'Отключено' }
                            </td>
                        </tr>
                        <tr>
                            <td>Мониторинг SSL:</td>
                            <td>{ (item.ssl_watch) ? 'Включено' : 'Отключено' }</td>
                        </tr>
                        <tr>
                            <td>Мониторинг срока регистрации домена:</td>
                            <td>{ (item.domain_watch) ? 'Включено' : 'Отключено' }</td>
                        </tr>
                        <tr>
                            <td>Проверка редиректа с http на https:</td>
                            <td>{ (item.ssl_redirect_watch) ? 'Включено' : 'Отключено' }</td>
                        </tr>
                        <tr>
                            <td>Учащенный мониторинг:</td>
                            <td>{ (item.frequent_checking) ? 'Включено' : 'Отключено' }</td>
                        </tr>
                        <tr>
                            <td>Таймаут запроса:</td>
                            <td>{ item.http_request_timeout } сек.</td>
                        </tr>
                        <tr>
                            <td>Разбор проблем:</td>
                            <td>
                                {item.parse_errors &&
                                    <>
                                        Включено
                                        {item.parse_errors_type === 'json' && <p>{item.parse_errors_type}: {item.parse_errors_pattern}</p>}
                                        {item.parse_errors_type === 'regexp' && <p>Регулярное выражение: {item.parse_errors_pattern}</p>}
                                    </>
                                }
                                { !item.parse_errors && 'Отключено' }
                            </td>
                        </tr>
                        <tr>
                            <td>Проверка контента страницы</td>
                            <td>{ item.monitor_content_enabled ? '' : 'Отключено' }
                                {item.monitor_content_enabled &&
                                    <>
                                        <span>{item.monitor_content_data}</span>
                                        <p>Логика {MonitorContentCheckLogicOptionsTitle(item.monitor_content_logic)} {MonitorContentCheckNotifyLogicOptionsTitle(item.monitor_content_notify_logic)} </p>
                                    </>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="my-2">
                <h4 className={styles.subtitle}>Время ответа за последние 24 часа:</h4>
                <MonitorAvgResponseTimeChart uuid={item.uuid} height={currentBreakpoint !== 'mini' ? 300 : 200}/>
            </div>
            <div className="my-2">
                <h4 className={styles.subtitle}>Доступность за последние 24 часа:</h4>
                <MonitorLastDayIncidentsRanges uuid={item.uuid} height={30}/>
            </div>
        </Box>
    );
};

export default MonitorDetails;