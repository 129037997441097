import React from 'react';

import {Control, FieldErrors, UseFormGetValues, UseFormRegister} from 'react-hook-form';

import Checkbox from '../../UI/Checkbox/Checkbox';
import HintQuestion from '../../UI/Hint/HintQuestion';
import HintPremium from '../../UI/Hint/HintPremium';
import Select from '../../UI/Input/Select';
import FormGroup from '../../UI/FormGroup/FormGroup';
import IMonitorOutput from '../../../models/Monitor/IMonitor';
import TextArea from '../../UI/TextArea/TextArea';
import {MonitorContentCheckLogicOptions, MonitorContentCheckNotifyLogicOptions} from './MonitorContentCheckOptions';


interface IMonitorContentExtraSetting {
    item?: IMonitorOutput,
    getValues: UseFormGetValues<any>,
    errors: FieldErrors<any>,
    control: Control<any>,
    register: UseFormRegister<any>,
    enabled: boolean|undefined,
}


const MonitorContentExtraSetting: React.FC<IMonitorContentExtraSetting> = ({
    item,
    getValues,
    control,
    register,
    enabled,
}) => {
    const isMonitorContentEnabled = getValues('monitor_content_enabled');

    return <FormGroup>
        <Checkbox
            disabled={!enabled}
            {...register('monitor_content_enabled')}
        >
            Проверка контента страницы <HintQuestion placement="right">
                <>
                    {/* eslint-disable-next-line max-len */}
                    <p>Можно ввести до 5 строк и выбрать из двух вариантов логики <b>и</b> или <b>или</b>. </p>
                    {/* eslint-disable-next-line max-len */}
                    <p> Доступная логика для уведомлений: <b>Уведомить если, присутствуют</b> или <b>Уведомить, если отсутствуют</b></p>
                    <p>Доступно только для GET и POST методов отправки запроса</p>
                </>
            </HintQuestion>
            {
                !enabled
                && <HintPremium condition={'monitor_content_enabled'}/>
            }
        </Checkbox>
        {isMonitorContentEnabled
            && <div>
                <label  className={'mt-2'} >Введите строки, которые нужно проверить</label>
                <TextArea
                    defaultValue={item?.monitor_content_data}
                    rows={5}
                    register={register}
                    name="monitor_content_data"
                    constraints={{required: true, minLength: 1}}
                />
                <div className="d-flex mt-2 align-content-end align-items-center">
                    <div className="col d-flex justify-content-end align-items-center">
                        <label style={{marginRight: '5px'}}>Логика:</label>
                        <Select
                            rules={{required: true}}
                            defaultValue={item?.monitor_content_logic}
                            hideIcon={true}
                            placeholder="И или ИЛИ"
                            control={control}
                            name="monitor_content_logic"
                            data={MonitorContentCheckLogicOptions}
                            searchable={false}
                            cleanable={false}
                        />
                        <div style={{minWidth: '285px', marginLeft: '5px'}}>
                            <Select
                                rules={{required: true}}
                                defaultValue={item?.monitor_content_notify_logic}
                                hideIcon={true}
                                placeholder="Логика уведомления"
                                control={control}
                                name="monitor_content_notify_logic"
                                data={MonitorContentCheckNotifyLogicOptions}
                                searchable={false}
                                cleanable={false}
                            />
                        </div>
                    </div>
                </div>

            </div>}
    </FormGroup>;
};

export default MonitorContentExtraSetting;