import React, {MouseEvent, useCallback, useState} from 'react';

import {toast} from 'react-toastify';

import {useDispatch} from 'react-redux';

import {TypedDispatch} from '../../types';

import IAdditionalEmail from '../../models/IAdditionalEmail';

import {additionalEmailAPI} from '../../api/additionalEmailAPI';

import StatusToggle from '../UI/StatusToggler/StatusToggle';
import TrashButton from '../UI/IconButton/TrashButton';
import NotifyButton from '../UI/Button/NotifyButton';

import {fetchMe} from '../../redux/authSlice';

import styles from '../TelegramChat/TelegramChats.module.scss';

interface IAdditionalEmailRowProps {
    item: IAdditionalEmail
}

const AdditionalEmailRow: React.FC<IAdditionalEmailRowProps> = ({item}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const [updateAdditionalEmail] = additionalEmailAPI.useUpdateAdditionalEmailMutation();
    const [deleteTelegramChat] = additionalEmailAPI.useDeleteAdditionalEmailMutation();
    const [apiSendTestNotify]  = additionalEmailAPI.useSendTestNotifyMutation();

    const [sendTestingNotifyState, setSendTestingNotifyState] = useState({
        disabled: !item.active,
        sending: false,
    });

    const onClickToggleStatus = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        try {
            await updateAdditionalEmail({
                id: item.id,
                active: !item.active,
            }).unwrap();
            toast.info(item.active ? 'E-mail деактивирован' : 'E-mail активирован');

        }catch (e) {}
    }, [item.active]);

    const onClickDelete = useCallback(async(event: MouseEvent) => {
        event.stopPropagation();
        try {
            await deleteTelegramChat(item.id).unwrap();
            dispatch(fetchMe());
            toast.info('E-mail удален');
        }catch (e) {}
    }, []);

    const onClickNotifyButton = async() => {
        setSendTestingNotifyState({
            disabled: false,
            sending: true,
        });

        try {
            await apiSendTestNotify(item.id).unwrap();
            toast.info('Уведомление отправлено');
        } catch (e) {
        }

        // Блокировка повторных нажатий (защита от спама)
        setSendTestingNotifyState({
            disabled: true,
            sending: false,
        });
        // Разблокировка через минуту.
        setTimeout(() => {
            setSendTestingNotifyState({
                disabled: false,
                sending: false,
            });
        }, 1000 * 60);
    };

    return (
        <tr className={styles.row}>
            <td>{item.email}</td>
            <td>{item.description}</td>
            <td><StatusToggle condition={item.active} onClick={onClickToggleStatus}/></td>
            <td><TrashButton onClick={onClickDelete} title="Удалить"/></td>
            <td>
                <NotifyButton
                    loading={sendTestingNotifyState.sending}
                    disabled={sendTestingNotifyState.disabled}
                    onClick={onClickNotifyButton}
                    className={styles.notifyBtn}>Отправить тестовое уведомление</NotifyButton>
            </td>
        </tr>
    );
};

export default AdditionalEmailRow;