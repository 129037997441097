/* eslint-disable camelcase */
import React, {useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';

import {useDispatch, useSelector} from 'react-redux';

import FormGroup from '../../UI/FormGroup/FormGroup';
import PrimaryButton from '../../UI/Button/PrimaryButton';

import {monitorAPI} from '../../../api/monitorAPI';
import MonitorFormType from '../../../models/Monitor/CreateMonitorType';
import CreateMonitorType from '../../../models/Monitor/CreateMonitorType';

import {userSelector} from '../../../redux/authSlice';

import FormGroupWithError from '../../UI/FormGroup/FormGroupWithError';

import ModalBodyWrapper from '../../UI/Modal/ModalBodyWrapper';

import MonitorCommonSettingsSubform from './MonitorCommonSettingsSubform';

import TextAreaUrl from '../../UI/TextArea/TextAreaUrl';

import BatchResult from '../../UI/BatchResult';
import IBatchResult from '../../../models/IBatchResult';

import HintQuestion from '../../UI/Hint/HintQuestion';

import useBatchOperation from '../../../hooks/useBatchOperation';

import {ADDITIONAL_EMAILS_RESOURCE_NAME, ApiUrls, TELEGRAM_CHATS_RESOURCE_NAME} from '../../../constants/urls';

import {idsToIri} from '../../../utils/iriHelper';

import styles from './MonitorForm.module.scss';

interface IMonitorBatchCreateFormProps {
    handleClose: () => void;
}


const MonitorBatchCreateForm: React.FC<IMonitorBatchCreateFormProps> = ({
    handleClose,
}) => {
    const dispatch = useDispatch();
    const user = useSelector(userSelector);
    const [showResult, setShowResult] = useState(false);
    const [result, setResult] = useState<IBatchResult | undefined>();
    const {trigger, isLoading} = useBatchOperation();

    const {register, handleSubmit, formState, control, setValue, getValues} = useForm<CreateMonitorType>({
        mode: 'onChange',
        defaultValues: {
            ssl_watch: user?.tariff.monitor_ssl_enabled,
            domain_watch: user?.tariff.monitor_domain_watch_enabled,
            ssl_redirect_watch: user?.tariff.monitor_ssl_redirect_watch_enabled,
            robots_txt_watch: user?.tariff.monitor_robots_txt_watch_enabled,
            method: 'HEAD',
            notify_by_email: user?.email_confirmed,
            http_request_timeout: 30,
        },
    });
    const {errors, isValid} = formState;


    const onSubmit: SubmitHandler<MonitorFormType> = async({ssl_watch, ...rest}) => {
        const data: MonitorFormType = rest;
        data.telegram_chats = idsToIri(data.telegram_chats, TELEGRAM_CHATS_RESOURCE_NAME);
        data.additional_emails = idsToIri(data.additional_emails, ADDITIONAL_EMAILS_RESOURCE_NAME);
        if (user?.tariff.monitor_ssl_enabled) {
            data.ssl_watch = ssl_watch;
        }
        if(data.http_request_timeout === 0) {
            data.http_request_timeout = 1;
        }
        try {
            await trigger(
                ApiUrls.MONITORS_BATCH_ACTION('create'),
                'POST',
                data,
                (result) => {
                    dispatch(monitorAPI.util.invalidateTags([{type: 'Monitor', id: 'LIST'}, 'SummaryMonitors']));
                    setResult(result);
                    setShowResult(true);
                }
            );

        } catch (e) {

        }
    };

    const handleCloseResult = () => {
        handleClose();
        setShowResult(false);
        setResult(undefined);
    };

    return (
        <>
            {
                (showResult && result)
                    ? <BatchResult
                        result={result}
                        handleClose={handleCloseResult}
                    />
                    : <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                        <ModalBodyWrapper>
                            <FormGroupWithError
                                error={errors?.url?.message}
                            >
                                <label>Список URL <HintQuestion placement="right">
                                    <p>Каждый URL с новой строки. URL должны начинаться с http или https, а также не должны содержать пробелов и других недопустимых для URL-формата символов.</p>
                                </HintQuestion></label>
                                <TextAreaUrl
                                    rows={5}
                                    register={register}
                                    placeholder="https://example.com"
                                    name="url"
                                />
                            </FormGroupWithError>

                            <MonitorCommonSettingsSubform
                                getValues={getValues}
                                errors={errors}
                                setValue={setValue}
                                control={control}
                                register={register}
                            />

                        </ModalBodyWrapper>
                        <FormGroup>
                            <PrimaryButton
                                type="submit"
                                loading={isLoading}
                                className="full-width"
                                disabled={!isValid}
                            >
                                Добавить несколько мониторов
                            </PrimaryButton>
                        </FormGroup>
                    </form>
            }
        </>
    );
};

export default MonitorBatchCreateForm;