import React, {useEffect, useState} from 'react';

import {useMutex} from 'react-context-mutex';
import {useParams} from 'react-router';

import {useDispatch} from 'react-redux';

import cn from 'classnames';

import {unwrapResult} from '@reduxjs/toolkit';

import {TypedDispatch} from '../types';

import Box from '../components/UI/Box/Box';
import LinkButton from '../components/UI/Button/LinkButton';
import {AppUrls} from '../constants/urls';
import BlankLayout from '../components/Layout/BlankLayout/BlankLayout';
import {confirmAdditionalEmail} from '../redux/authSlice';

import {useIsMount} from '../hooks/useIsMount';

import styles from '../components/Auth/Auth.module.scss';

const AdditionalEmailConfirm: React.FC = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const MutexRunner = useMutex();
    const mutex = new MutexRunner('additionalEmailConfirm');
    const isMount = useIsMount();
    const {token} = useParams();

    const [message, setMessage] = useState<string>();
    const [status, setStatus] = useState<boolean>(false);

    useEffect(() => {
        if(!token || !isMount) {
            return;
        }
        mutex.run(async() => {
            mutex.lock();
            try {
                const resultAction = await dispatch(confirmAdditionalEmail({token}));

                const response = unwrapResult(resultAction);
                setMessage(response.message);
                setStatus(response.status);
            } catch (error: any) {
                setMessage(error.message);
                setStatus(false);
            }finally{
                mutex.unlock();
            }
        });
    }, [token]);

    return (
        <BlankLayout>
            <div className={styles.wrapper}>
                <div className={styles.box_wrapper_confirm}>
                    <Box>
                        <div className="row text-center mb-3">
                            <div className="col-12">
                                <h2 className={styles.confirm_title}>Подтверждение доп. E-mail</h2>
                                {
                                    message && (
                                        <p className={cn('py-2 m-0', [status
                                            ? styles.confirm_email__msg
                                            : styles.confirm_email__msg_error])}
                                        >
                                            {message}
                                        </p>
                                    )
                                }
                            </div>
                        </div>

                        <LinkButton
                            href={AppUrls.SETTINGS}
                            className="full-width rs-btn-primary"
                        >Продолжить</LinkButton>
                    </Box>
                </div>
            </div>
        </BlankLayout>
    );
};

export default AdditionalEmailConfirm;