/* eslint-disable camelcase */
import React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';

import {useSelector} from 'react-redux';

import {toast} from 'react-toastify';

import FormGroup from '../../UI/FormGroup/FormGroup';
import PrimaryButton from '../../UI/Button/PrimaryButton';

import {monitorAPI} from '../../../api/monitorAPI';
import MonitorFormType from '../../../models/Monitor/CreateMonitorType';
import CreateMonitorType from '../../../models/Monitor/CreateMonitorType';

import IMonitorOutput from '../../../models/Monitor/IMonitor';

import {userSelector} from '../../../redux/authSlice';

import FormGroupWithError from '../../UI/FormGroup/FormGroupWithError';

import InputText from '../../UI/Input/InputText';

import InputUrl from '../../UI/Input/InputUrl';

import ModalBodyWrapper from '../../UI/Modal/ModalBodyWrapper';

import MonitorCommonSettingsSubform from './MonitorCommonSettingsSubform';

import {ADDITIONAL_EMAILS_RESOURCE_NAME, TELEGRAM_CHATS_RESOURCE_NAME} from '../../../constants/urls';

import {idsToIri} from '../../../utils/iriHelper';


import styles from './MonitorForm.module.scss';

interface IMonitorFormProps {
    onSuccess: () => void,
    item?: IMonitorOutput,
}

const MonitorForm: React.FC<IMonitorFormProps> = ({onSuccess, item}) => {
    const user = useSelector(userSelector);
    const [addMonitor, {isLoading: isLoadingCreate}] = monitorAPI.useAddMonitorMutation();
    const [updateMonitor, {isLoading: isLoadingUpdate}] = monitorAPI.useUpdateMonitorMutation();

    const isNew = !item;

    const isSslChecked = user?.tariff.monitor_ssl_enabled
        && (item?.ssl_watch || isNew);
    const isDomainWatchChecked = user?.tariff.monitor_domain_watch_enabled
        && (item?.domain_watch || isNew);
    const isSslRedirectWatchChecked = user?.tariff.monitor_ssl_redirect_watch_enabled
        && (item?.ssl_redirect_watch || isNew);
    const isRobotsTxtWatchChecked = user?.tariff.monitor_robots_txt_watch_enabled
        && (item?.robots_txt_watch || isNew);
    const isNotifyByEmailChecked = user?.email_confirmed
        && (item?.notify_by_email || isNew);

    const method = item?.method ?? 'HEAD';
    const {register, handleSubmit, formState, control, setValue, getValues} = useForm<CreateMonitorType>({
        mode: 'onChange',
        defaultValues: {
            ssl_watch: isSslChecked,
            domain_watch: isDomainWatchChecked,
            ssl_redirect_watch: isSslRedirectWatchChecked,
            robots_txt_watch: isRobotsTxtWatchChecked,
            parse_errors: item?.parse_errors,
            frequent_checking: item?.frequent_checking,
            method,
            parse_errors_type: item?.parse_errors_type ?? 'json',
            notify_by_email: isNotifyByEmailChecked,
            telegram_chats: item?.telegram_chats?.map(chat => `${chat.id}`),
            additional_emails: item?.additional_emails?.map(email => `${email.id}`),
            http_request_timeout: item?.http_request_timeout ?? 30,
            notify_email_confirmed_delay: item?.notify_email_confirmed_delay ?? 0,
            monitor_content_enabled: item?.monitor_content_enabled ?? false,
            monitor_content_data: item?.monitor_content_data,
            monitor_content_logic: item?.monitor_content_logic,
            monitor_content_notify_logic: item?.monitor_content_notify_logic,
        },
    });
    const {errors, isValid, isDirty} = formState;


    const onSubmit: SubmitHandler<MonitorFormType> = async({
        ssl_watch,
        ...rest
    }) => {
        const data: MonitorFormType = rest;
        data.telegram_chats = idsToIri(data.telegram_chats, TELEGRAM_CHATS_RESOURCE_NAME);
        data.additional_emails = idsToIri(data.additional_emails, ADDITIONAL_EMAILS_RESOURCE_NAME);

        if (user?.tariff.monitor_ssl_enabled) {
            data.ssl_watch = ssl_watch;
        }
        if(data.http_request_timeout === 0) {
            data.http_request_timeout = 1;
        }

        try {
            if (isNew){
                await addMonitor(data).unwrap();
                toast('Монитор добавлен');
            } else {
                await updateMonitor({
                    uuid: item?.uuid,
                    ...data,
                }).unwrap();
                toast('Монитор изменен');
            }

            onSuccess();
        } catch (e) {

        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <ModalBodyWrapper>
                <FormGroupWithError
                    error={errors?.url?.message}
                    label="URL сайта"
                >
                    <InputUrl
                        register={register}
                        defaultValue={item?.url_human_readable}
                        name="url"
                        placeholder="https://example.com"
                        control={control}
                    />
                </FormGroupWithError>

                <FormGroupWithError
                    error={errors?.name?.message}
                    label="Короткое название"
                >
                    <InputText
                        defaultValue={item?.name}
                        name="name"
                        placeholder="Короткое название"
                        register={register}
                        control={control}
                    />
                </FormGroupWithError>

                <MonitorCommonSettingsSubform
                    getValues={getValues}
                    errors={errors}
                    setValue={setValue}
                    control={control}
                    register={register}
                    item={item}
                />

            </ModalBodyWrapper>
            <FormGroup>
                <PrimaryButton
                    type="submit"
                    loading={isLoadingCreate || isLoadingUpdate}
                    className="full-width"
                    disabled={!(isValid && (isDirty || isNew))}
                >
                    {isNew ? 'Добавить' : 'Изменить'}
                </PrimaryButton>
            </FormGroup>
        </form>
    );
};

export default MonitorForm;