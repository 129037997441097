import React, {useState} from 'react';

import Table from '../UI/Table/Table';
import TableBox from '../UI/Box/TableBox/TableBox';

import {additionalEmailAPI} from '../../api/additionalEmailAPI';

import CreateAdditionalEmailButton from './CreateAdditionalEmailButton';

import AdditionalEmailRow from './AdditionalEmailRow';

import IOrderBy from '../../models/IOrderBy';

import styles from '../TelegramChat/TelegramChats.module.scss';

const AdditionalEmails: React.FC = () => {
    const [sort, setSort] = useState<IOrderBy>();
    const {data: items, isLoading} = additionalEmailAPI.useGetAdditionalEmailsQuery(sort);

    return (
        <TableBox>
            <div className={styles.head}>
                <h3>Дополнительные E-mail для уведомлений</h3>
                <CreateAdditionalEmailButton/>
            </div>

            <Table
                columns={[
                    {label: 'E-mail', sort: 'email'},
                    {label: 'Описание', sort: 'description'},
                    {label: 'Активен', sort: 'active'},
                    {label: ''},
                    {label: ''},
                ]}
                items={items}
                rowFactory={item => <AdditionalEmailRow
                    key={item.id}
                    item={item}
                />}
                isLoading={isLoading}
                sort={sort}
                setSort={setSort}
            />
        </TableBox>
    );
};

export default AdditionalEmails;