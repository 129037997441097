import {createApi} from '@reduxjs/toolkit/query/react';

import httpBaseQuery from '../rtk-query/httpBaseQuery';

import IAdditionalEmail, {
    IAdditionalEmailAdd,
    IAdditionalEmailConfirm,
    IAdditionalEmailEdit,
} from '../models/IAdditionalEmail';
import {ApiUrls} from '../constants/urls';
import IOrderBy from '../models/IOrderBy';
import {buildQuery} from '../utils/query';

import {providesList} from '../rtk-query/RTKQueryHelper';

export const additionalEmailAPI = createApi({
    reducerPath: 'additionalEmailAPI',
    baseQuery: httpBaseQuery(),
    tagTypes: ['AdditionalEmail', 'AdditionalEmailActive'],
    refetchOnMountOrArgChange: 60,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    endpoints: (build) => ({
        addAdditionalEmail: build.mutation<IAdditionalEmail, IAdditionalEmailAdd>({
            query: (data) => ({
                path: ApiUrls.ADDITIONAL_EMAILS(),
                method: 'POST',
                data,
            }),
            invalidatesTags: [{ type: 'AdditionalEmail', id: 'LIST' }, { type: 'AdditionalEmailActive', id: 'LIST' }],
        }),
        getAdditionalEmails: build.query<IAdditionalEmail[], IOrderBy|undefined>({
            query: (sort) => ({
                path: buildQuery(ApiUrls.ADDITIONAL_EMAILS(sort)),
            }),
            providesTags: (result) => providesList(result, 'AdditionalEmail'),
        }),
        getActiveAdditionalEmails: build.query<IAdditionalEmail[], void>({
            query: () => ({
                path: buildQuery(ApiUrls.ADDITIONAL_EMAILS(undefined, ['active=true'])),
            }),
            providesTags: (result) => providesList(result, 'AdditionalEmailActive'),
        }),
        getAdditionalEmail: build.query<IAdditionalEmail, number>({
            query: (id) => ({
                path: ApiUrls.ADDITIONAL_EMAIL(id),
            }),
            providesTags: (result, error, id) => [{ type: 'AdditionalEmail', id }],
        }),
        updateAdditionalEmail: build.mutation<IAdditionalEmail, IAdditionalEmailEdit>({
            query: ({id, ...data}) => ({
                path: ApiUrls.ADDITIONAL_EMAIL(id),
                method: 'PUT',
                data,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch }) {
                dispatch(
                    additionalEmailAPI.util.updateQueryData('getAdditionalEmail', id, (draft) => {
                        Object.assign(draft, patch);
                    })
                );
            },
            invalidatesTags: [{ type: 'AdditionalEmail', id: 'LIST' }, { type: 'AdditionalEmailActive', id: 'LIST' }],
        }),
        deleteAdditionalEmail: build.mutation<void,  number>({
            query: (id) => ({
                path: ApiUrls.ADDITIONAL_EMAIL(id),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [
                { type: 'AdditionalEmail', id: 'LIST' },
                { type: 'AdditionalEmail', id },
                { type: 'AdditionalEmailActive', id: 'LIST' },
                { type: 'AdditionalEmailActive', id },
            ],
        }),
        sendTestNotify: build.mutation<void, number>({
            query: (id) => ({
                path: ApiUrls.ADDITIONAL_EMAIL_SEND_TEST_NOTIFY(id),
                method: 'POST',
                data: {},
            }),
        }),
        addAdditionalEmailWithConfirmed: build.mutation<IAdditionalEmail, IAdditionalEmailAdd>({
            query: (data) => ({
                path: ApiUrls.ADDITIONAL_EMAILS_CONFIRMED(),
                method: 'POST',
                data: data,
            }),
        }),
    }),
});