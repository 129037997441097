import {toast} from 'react-toastify';

import {isDev} from 'utils/evironment';


type apiErrorType = {
    message: string,
    response: responseDataType
};

type responseDataType = {
    data: {
        violations?: violationType[],
        detail?: string,
        message?: string,
    }
};

type violationType = {
    message: string
};


export const apiErrorHandler = async(error: apiErrorType, errorMessage: string, silent: boolean) => {
    const defaultErrorMessage = 'На сервере произошла ошибка, мы уже знаем об этом и работаем над исправлением. Пожалуйста, попробуйте повторить действие позднее или свяжитесь с нашей службой технической поддержки.';
    // eslint-disable-next-line no-console
    isDev() && console.error(error.response.data);
    const response = error.response;
    let messages = [defaultErrorMessage];
    if (response?.data?.violations) {
        messages = response.data.violations
            .map((violation) => violation.message);
    } else if (response?.data?.detail) {
        messages = [response.data.detail];
    } else if (response?.data?.message) {
        messages = [response.data.message];
    } else if (errorMessage) {
        messages = [errorMessage || defaultErrorMessage];
    }
    if (!silent) {
        toast.warning(messages.join('<br>'));
    }

    return Promise.reject(new Error(messages.join('\n')));
};