import React from 'react';
import {Link} from 'react-router-dom';

import {Control, FieldErrors, UseFormRegister} from 'react-hook-form';


import {useSelector} from 'react-redux';

import Checkbox from '../../UI/Checkbox/Checkbox';

import IMonitorOutput from '../../../models/Monitor/IMonitor';

import {userSelector} from '../../../redux/authSlice';

import {AppUrls} from '../../../constants/urls';

import {telegramChatAPI} from '../../../api/telegramChatAPI';
import {additionalEmailAPI} from '../../../api/additionalEmailAPI';

import DelaySelector from '../../UI/DelaySelector/DelaySelector';

import DelaySelectorTelegram from '../../UI/DelaySelector/DelaySelectorTelegram';
import DelaySelectorAdditionalEmail from '../../UI/DelaySelector/DelaySelectorAdditionalEmail';

import HintPremium from '../../UI/Hint/HintPremium';

import styles from './MonitorForm.module.scss';

interface INotifySettingsSubformProps {
    item?: IMonitorOutput,
    errors: FieldErrors<any>,
    control: Control<any>,
    register: UseFormRegister<any>,
}


const NotifySettingsSubform: React.FC<INotifySettingsSubformProps> = ({
    item,
    control,
    register,
}) => {
    const user = useSelector(userSelector);
    //const isNew = !item;
    const {data: chats, isLoading: isLoadingChats} = telegramChatAPI.useGetActiveTelegramChatsQuery();
    const {data: additionalEmails, isLoading: isLoadingAdditionalEmails} = additionalEmailAPI.useGetActiveAdditionalEmailsQuery();

    return (
        <>
            <div className="form-group d-flex align-items-center">
                <div className="col">
                    <Checkbox
                        disabled={!user?.email_confirmed}
                        {...register('notify_by_email')}
                    >
                        Уведомлять на подтвержденный Email
                    </Checkbox>
                </div>
                {
                    !user?.email_confirmed
                    && <div className={'col ' + styles.error}>Необходимо подтвердить Email</div>
                }
                <DelaySelector
                    control={control}
                    name="notify_email_confirmed_delay"
                    value={item?.notify_email_confirmed_delay ?? 0}
                />
            </div>

            <div className="form-group d-flex align-items-center">
                Уведомления на дополнительные адреса Email
                {
                    user?.tariff?.additional_emails_max_count_limit !== undefined
                    && user.tariff.additional_emails_max_count_limit <= 0
                    && <HintPremium condition={'additional_emails_max_count_limit'} />
                }
                {
                    !isLoadingAdditionalEmails && additionalEmails?.length === 0
                    && user?.tariff?.additional_emails_max_count_limit !== undefined
                    && user.tariff.additional_emails_max_count_limit > 0
                    && <div className={styles.error}>
                        Необходимо добавить один или несколько Email
                        в <Link to={AppUrls.SETTINGS}>Настройках</Link> и активировать
                    </div>
                }
            </div>
            {
                !isLoadingAdditionalEmails
                && additionalEmails
                && additionalEmails.length > 0
                && additionalEmails.map((email, index) => (
                    <div
                        key={email.id}
                        className="form-group d-flex align-items-center"
                    >
                        <div className="col">
                            <Checkbox
                                value={`${email.id}`}
                                {...register('additional_emails')}
                            >
                                {email.description ?? email.email}
                            </Checkbox>
                        </div>

                        <DelaySelectorAdditionalEmail
                            index={index}
                            control={control}
                            register={register}
                            values={item?.notify_additional_emails_settings ?? []}
                            emailId={email.id}
                        />

                    </div>
                ))
            }

            <div className="form-group d-flex align-items-center">
                Уведомления через Telegram
                {
                    !isLoadingChats && chats?.length === 0
                    && <div className={styles.error}>
                        Необходимо добавить один или несколько чатов
                        в <Link to={AppUrls.SETTINGS}>Настройках</Link> и активировать
                    </div>
                }
            </div>

            {
                !isLoadingChats
                && chats
                && chats.length > 0
                && chats.map((chat, index) => (
                    <div
                        key={chat.id}
                        className="form-group d-flex align-items-center"
                    >
                        <div className="col">
                            <Checkbox
                                value={`${chat.id}`}
                                {...register('telegram_chats')}
                            >
                                {chat.description ?? chat.chat}
                            </Checkbox>
                        </div>

                        <DelaySelectorTelegram
                            index={index}
                            control={control}
                            register={register}
                            values={item?.notify_telegram_chats_settings ?? []}
                            chatId={chat.id}
                        />

                    </div>
                ))
            }
        </>
    );
};

export default NotifySettingsSubform;