import React from 'react';
import { RegisterOptions } from 'react-hook-form';

import TextArea, { TextAreaProps } from './TextArea';

const TextAreaUrl: React.FC<TextAreaProps> = (props) => {
    const PATTERN = /^https?:\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%\-/]))?/;

    const validateUrls = (value: string) => {
        const urls = value.split(/\r?\n/).filter((line) => line.trim() !== '');
        const invalidUrls = urls.filter((url) => !PATTERN.test(url));
        return invalidUrls.length === 0 || `Некорректный URL: ${invalidUrls.join(', ')}`;
    };

    const rules: RegisterOptions = {
        validate: validateUrls,
        required: 'Укажите хотя бы один URL',
    };

    return (
        <TextArea
            constraints={rules}
            {...props}
        />
    );
};

export default TextAreaUrl;