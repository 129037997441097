import React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';

import {useDispatch} from 'react-redux';

import {toast} from 'react-toastify';

import ModalBodyWrapper from '../UI/Modal/ModalBodyWrapper';

import FormGroupWithError from '../UI/FormGroup/FormGroupWithError';

import {additionalEmailAPI} from '../../api/additionalEmailAPI';

import InputName from '../UI/Input/InputName';

import {TypedDispatch} from '../../types';

import {IAdditionalEmailAdd} from '../../models/IAdditionalEmail';

import {fetchMe} from '../../redux/authSlice';


import FormGroup from '../UI/FormGroup/FormGroup';
import PrimaryButton from '../UI/Button/PrimaryButton';

import styles from '../Admin/Tariffs/TariffForm/TariffForm.module.scss';


interface IAdditionalEmailFormProps {
    onSuccess: () => void,
}

const AdditionalForm: React.FC<IAdditionalEmailFormProps> = ({onSuccess}) => {
    const dispatch = useDispatch<TypedDispatch>();

    // const [addAdditionalChat, {isLoading}] = additionalEmailAPI.useAddAdditionalEmailMutation();
    const [addAdditionalChat, {isLoading}] = additionalEmailAPI.useAddAdditionalEmailWithConfirmedMutation();

    const {register, handleSubmit, formState, control} = useForm<IAdditionalEmailAdd>({mode: 'onChange'});
    const {errors, isValid} = formState;

    const onSubmit: SubmitHandler<IAdditionalEmailAdd> = async(data) => {
        addAdditionalChat(data).unwrap().then((response: any) => {
            toast.info(response.message ?? 'Отправлено сообщение для подтверждения Email');
            dispatch(fetchMe());
            onSuccess();
        });
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                <ModalBodyWrapper>
                    <p className="mb-3">
                        {/* eslint-disable-next-line max-len */}
                        После нажатия на кнопку &quot;Добавить&quot; на добавляемый email будет выслано письмо с подтверждением, которое будет действительно 5 минут
                    </p>
                    <p className="mb-3">
                        {/* eslint-disable-next-line max-len */}
                        Только после подтверждения можно будет использовать дополнительный email
                    </p>
                    <FormGroupWithError
                        error={errors?.email?.message}
                        label="E-mail"
                    >
                        <InputName
                            control={control}
                            name="email"
                            placeholder="example@example.ru"
                            register={register}
                            required
                        />
                    </FormGroupWithError>
                    <FormGroupWithError
                        error={errors?.description?.message}
                        label="Описание"
                    >
                        <InputName
                            defaultValue="Мой первый дополнительный e-mail"
                            control={control}
                            name="description"
                            placeholder="Укажите описание"
                            register={register}
                            required
                        />
                    </FormGroupWithError>
                </ModalBodyWrapper>
                <FormGroup>
                    <PrimaryButton
                        type="submit"
                        loading={isLoading}
                        className="full-width"
                        disabled={!isValid}
                    >
                        Добавть
                    </PrimaryButton>
                </FormGroup>
            </form>
        </div>
    );
};

export default AdditionalForm;