export const MonitorContentCheckNotifyLogicOptions = [
    {label: 'Уведомить, если присутствуют', value: 'present'},
    {label: 'Уведомить, если отсутствуют', value: 'not-present'},
];

export const MonitorContentCheckNotifyLogicOptionsTitle = (logic: string | number | undefined): string => {
    const foundLogic = MonitorContentCheckNotifyLogicOptions
        .filter((option)  => option.value === logic)
        .pop();
    if(foundLogic !== undefined) {
        return foundLogic.label;
    }

    return '-';
};

export const MonitorContentCheckLogicOptions = [
    {label: 'И', value: 'and'},
    {label: 'ИЛИ', value: 'or'},
];

export const MonitorContentCheckLogicOptionsTitle = (logic: string | number | undefined): string => {
    const foundLogic = MonitorContentCheckLogicOptions
        .filter((option)  => option.value === logic)
        .pop();
    if(foundLogic !== undefined) {
        return foundLogic.label;
    }

    return '-';
};