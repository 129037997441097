import React, {MouseEvent, useCallback, useState} from 'react';

import ITariff from '../../models/ITariff';
import TariffModal from './TariffModal';
import IUserOutput from '../../models/IUser';
import {getFormattedDate} from '../../utils/datetime';
import PrimaryButton from '../UI/Button/PrimaryButton';


import Logo from '../Icons/Logo';

import styles from './Tariff.module.scss';

interface ISingleTariffProps {
    item: ITariff,
    user: IUserOutput | null,
}

const Tariff: React.FC<ISingleTariffProps> = ({item, user}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClose = () => setIsModalOpen(false);

    const onPayClick = useCallback((event: MouseEvent<HTMLTableRowElement>) => {
        event.stopPropagation();
        setIsModalOpen(true);
    }, []);

    const isActive = item.id === user?.tariff.id;

    const classList = [];
    if (isActive) {
        classList.push(styles.container_active);
    } else if (item.is_archive) {
        classList.push(styles.container_archive);
    } else if (item.is_recommended) {
        classList.push(styles.container_recommend);
    } else {
        classList.push(styles.container_default);
    }

    const classMap = classList.join(' ');

    return (
        <>
            <div className={classMap}>
                {isActive && (
                    <div className={styles.label_active}>
                        {getFormattedDate(user.active_to, {format: 'DD.MM.YYYY', nullValue: 'Бессрочно'})}
                    </div>
                )}
                {item.is_recommended && (
                    <div className={styles.label_recommended}>
                        <Logo />
                        <span>РЕКОМЕНДУЕТ</span>
                    </div>
                )
                }
                <div className={styles.title}>{item.name}</div>
                <div className={styles.description}>
                    {
                        item.is_archive
                        && 'После активации другого тарифа, на этот вернуться будет невозможно.'
                    }
                </div>
                <div className={styles.period}>
                    <div>{item.period > 0 ? `${item.period} дн.` : 'Бессрочно'}</div>
                    <span className={styles.text}>Срок действия </span>
                </div>
                <div className={styles.instances_limit}>
                    <div className={styles.number}>{`${item.monitor_min_check_interval_limit} мин`}</div>
                    <span className={styles.text}>Интервал мониторинга</span>
                </div>
                <div className={styles.monitor_limit_max}>
                    <div className={styles.number}>{item.monitor_max_count_limit}</div>
                    <span className={styles.text}>Мониторов</span>
                </div>
                <div className={styles.monitor_limit_max}>
                    <div className={styles.number}>{item.telegram_chats_max_count_limit}</div>
                    <span className={styles.text}>Telegram чатов</span>
                </div>
                <div className={styles.monitor_limit_max}>
                    <div className={styles.number}>{item.additional_emails_max_count_limit > 0 ? item.additional_emails_max_count_limit :' Нет'}</div>
                    <span className={styles.text}>Дополнительных E-mail</span>
                </div>
                <div className={styles.monitor_limit}>
                    <div className={styles.number}>{item.monitor_ssl_enabled ? 'Да' : 'Нет'}</div>
                    <span className={styles.text}>Мониторинг SSL</span>
                </div>
                <div className={styles.monitor_limit}>
                    <div className={styles.number}>{item.monitor_domain_watch_enabled ? 'Да' : 'Нет'}</div>
                    <span className={styles.text}>Мониторинг домена</span>
                </div>
                <div className={styles.monitor_limit}>
                    <div className={styles.number}>{item.monitor_ssl_redirect_watch_enabled ? 'Да' : 'Нет'}</div>
                    <span className={styles.text}>Проверка редиректа https</span>
                </div>
                <div className={styles.monitor_limit}>
                    <div className={styles.number}>{item.monitor_robots_txt_watch_enabled ? 'Да' : 'Нет'}</div>
                    <span className={styles.text}>Проверка robots.txt</span>
                </div>
                <div className={styles.monitor_limit}>
                    <div className={styles.number}>{item.monitor_extended_methods ? 'Да' : 'Нет'}</div>
                    <span className={styles.text}>Расширенный мониторинг</span>
                </div>
                <div className={styles.monitor_limit}>
                    <div className={styles.number}>{item.monitor_frequent_checking ? 'Да' : 'Нет'}</div>
                    <span className={styles.text}>Учащенный мониторинг</span>
                </div>
                <div className={styles.price}>
                    <span className={styles.text}>Стоимость</span>
                    <div className={styles.price_cost}>{item.price > 0 ? item.price : '0'} <span>руб</span></div>
                </div>
                <div className={styles.button_container}>
                    {(() => {
                        switch (true) {
                            case item.price > 0 :
                                return <PrimaryButton
                                    onClick={onPayClick}
                                    className={styles.button_default}
                                >Оплатить
                                </PrimaryButton>;
                            default:
                                return <PrimaryButton
                                    className={styles.button_free}
                                    disabled
                                >Бесплатно</PrimaryButton>;
                        }
                    })()}
                </div>
            </div>
            <TariffModal open={isModalOpen} handleClose={handleClose} item={item}/>
        </>
    );
};

export default Tariff;